(function($) {
  let $gotop = $('.gotop');
  let menuOpenSpeed = 300;
  let isMobile = false;
  let submenuShow = false;
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

  // IE關閉smooth scroll
  if(navigator.userAgent.match(/Trident\/7\./)) {
    document.body.addEventListener("mousewheel", function() {
      event.preventDefault();
      let wd = event.wheelDelta;
      let csp = window.pageYOffset;
      window.scrollTo(0, csp - wd);
    });
  }

  // $('.main').css('min-height', $(window).height() - $('.header').outerHeight() - $('.footer').outerHeight())

  $('.nav-toggle').click(function(event) {
    $('body').toggleClass('nav-open');
    if ($('body').hasClass('nav-open')) {
      if ($('.submenu-holder').is(':visible')) {
        $('.nav').css('max-height', $(window).height());
      } else {
        $('.nav').css('max-height', $(window).height() - $('.header').outerHeight());
      }
      // $('html,body').scrollTop(0);
    } else {
      $('.nav').css('max-height', 0);
    }
    if ($('.submenu-holder').is(':visible')) {
      $('.nav, .nav-inner').css('min-height', $(window).height());
    } else {
      $('.nav, .nav-inner').css('min-height', 0);
    }
    return false;
  });

  $('.menu li, .side-nav li').has('ul').addClass('has-child');

  $('.menu li.has-child > a').click(function(event) {
    $(this).parent().toggleClass('open');
    $(this).parent().siblings('li').removeClass('open');
    if ($('.submenu-holder').is(':hidden')) {
      $(this).siblings('ul').stop().slideToggle(menuOpenSpeed);
      $(this).parent().siblings('li').children('ul').stop().slideUp(menuOpenSpeed);
      $(this).siblings('ul').find('li').removeClass('open');
      $(this).siblings('ul').find('ul').stop().slideUp(menuOpenSpeed);
    } else {
      var cloneMenu = $(this).next('ul').clone();
      cloneMenu.attr('style', '');
      if ($(this).parent().hasClass('open')) {
        $('.submenu-holder').height($('.nav-inner').outerHeight());
        $('.submenu-holder').html(cloneMenu).addClass('show');
      } else {
        $('.submenu-holder').html(cloneMenu).removeClass('show');
      }
    }
    if (isMobile) {
      return false;
    }
  });
  $('.menu li').hover(function(event) {
    if (!isMobile) {
      if ($('.submenu-holder').is(':visible')) {
        $(this).siblings('li').removeClass('open');
        if ($(this).hasClass('has-child')) {
          $(this).addClass('open');
          // console.log($(this).children('ul').clone());
          var cloneMenu = $(this).children('ul').clone();
          cloneMenu.attr('style', '');
          $('.submenu-holder').height($('.nav-inner').outerHeight());
          $('.submenu-holder').html(cloneMenu).addClass('show');
          submenuShow = true;
        } else {
          submenuShow = false;
        }
      }
    }
  }, function() {
    if (!isMobile) {
      if ($('.submenu-holder').is(':visible')) {
        if (!submenuShow) $(this).removeClass('open');
        $('.submenu-holder').removeClass('show');
      }
    }
  });
  $('.submenu-holder').hover(function() {
    if (submenuShow) $(this).addClass('show');
  }, function() {
    $(this).removeClass('show');
    $('.menu li').removeClass('open');
  })

  // $('.lang > a').on('click', function(e) {
  //   $(this).parent('.lang').toggleClass('open');
  //   $(this).next('.lang-list').stop().slideToggle(menuOpenSpeed)
  //   return false;
  // });

  $(document).on('click', function(e){
    if($(e.target).parents('.menu').length==0){
      $('.menu li.has-child').removeClass('open');
      $('.menu li.has-child > ul').stop().slideUp(menuOpenSpeed);
    }
    if($(e.target).parents('.nav').length==0){
      if ($('body').hasClass('nav-open') && $('.submenu-holder').is(':hidden'))  {
        $('.nav').css('max-height', 0);
      }
      $('body').removeClass('nav-open');
    }
    if($(e.target).parents('.content-nav-sub').length==0){
      $('.content-nav-sub .bt').removeClass('open');
    }
  });
  // .on('focusin', '.field', function(event) {
  //   $(this).parent('.field-box').addClass('is-focus');
  // }).on('focusout', '.field', function(event) {
  //   $(this).parent('.field-box').removeClass('is-focus');
  //   if ($(this).val()!=='') {
  //     $(this).parent('.field-box').addClass('is-filled');
  //     $(this).parent('.field-box').removeClass('has-error');
  //   } else {
  //     $(this).parent('.field-box').removeClass('is-filled');
  //     if ($(this).prop('required')) {
  //       $(this).parent('.field-box').addClass('has-error');
  //     }
  //   }
  // });
  // if ($('.field').val()!=='') {
  //   $('.field').parent('.field-box').addClass('is-filled');
  // }
  $('.content-nav-sub .bt').on('click', function(event) {
    $(this).toggleClass('open');
    return false;
  });

  $gotop.click(function(event) {
    $('html,body').stop().animate({
      scrollTop: 0
    }, 400);
    return false;
  });

  $(window).on('scroll', function(event) {
    if ($(window).scrollTop() > 100) {
      $('body').addClass('header-fixed');
    } else {
      $('body').removeClass('header-fixed');
    }
    if ($(window).scrollTop() > 200) {
      $gotop.fadeIn();
    } else {
      $gotop.hide();
    }
  }).scroll();
  $(window).on('resize', function(event) {
    $('.main').css('min-height', $(window).height() - $('.footer').outerHeight())
    if ($('.content-nav .title').is(':hidden')) {
      $(".content-nav .container").mCustomScrollbar({
        axis:"x",
        theme:"dark",
        scrollbarPosition: "outside",
        alwaysShowScrollbar: 0
      });
    } else {
      $(".content-nav .container").mCustomScrollbar("destroy");
    }
  }).resize();
})($)
